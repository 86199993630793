
import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';
import Image from '../../assets/admin/img/avatar.png';

import { UserContext } from '../../context/UserContext';
function Profile() {

    document.title = " Profiles | Mobile Voucher"; // this will show the page title name
    //let set the input veriable details here
    const [isLoading, setIsLoading] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [btLoading, setBtLoading] = useState(false);
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };


    const { my_photo, user } = useContext(UserContext);
    const [photo_status, setPhtotStatus] = my_photo;

    const [user_details] = user;

    const [userInput, setUserdetails] = useState({
        name: '',
        email: '',
        phone: '',
        sex: '',
        state: '',
        location: '',
        address: '',
        username: '',
        dob: '',
        occupation: '',
        married_status: '',
        acctno: '',
        acct_name: '',
        bankname: '',
        error_list: [],

    });
    const [picture, setPicture] = useState([]);
    const [display_image, setDisplay_image] = useState([]);

    /* this handle image fields */
    const handleImage = (e) => {
        setPicture({ image: e.target.files[0] });
        // I am adding uploadedImage key just to go with your implementation.
        setDisplay_image({ uploadedImage: true, photo: URL.createObjectURL(e.target.files[0]) })
        setPhtotStatus({ uploadedImage: true, photo: URL.createObjectURL(e.target.files[0]) })
    }

    /* this handle inputs fields */
    const handleInput = (e) => {
        e.persist();
        setUserdetails({ ...userInput, [e.target.name]: e.target.value });
    }
    const [error_list, setError] = useState([]);
    //update profile function goes here

    const updateProfile = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('name', userInput.name);
        formData.append('email', userInput.email);
        formData.append('phone', userInput.phone);
        formData.append('sex', userInput.sex);
        formData.append('state', userInput.state);
        formData.append('location', userInput.location);
        formData.append('address', userInput.address);
        formData.append('occupation', userInput.occupation);
        formData.append('married_status', userInput.married_status);
        formData.append('acctno', userInput.acctno);
        formData.append('acct_name', userInput.acct_name);
        formData.append('bankname', userInput.bankname);
        formData.append('username', userInput.username);
        formData.append('dob', userInput.dob);

        axios.post(`/api/update_user`, formData).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.message, { position: 'top-center', theme: 'colored' });
                //history.push('/admin/view-product');
                setError([]);
            }
            else if (res.data.status === 402) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            else if (res.data.status === 422) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
                setError(res.data.errors);
            }

            else {
                toast.info("Something went wrong! Try again");

            }
            setLoading(false);
        });

    }


    //update profile image
    const updateProfileImage = (e) => {
        e.preventDefault();
        setBtLoading(true);
        const formData = new FormData();
        formData.append('image', picture.image);
        axios.post(`/api/update_user_image`, formData).then(res => {
            if (res.data.status === 200) {
                //success message
                toast.success(res.data.message, { position: 'top-center', theme: 'colored' });
                //history.push('/admin/view-product');
                setError([]);
                getUserDetails();
                toggleShow(!show)
            }
            else if (res.data.status === 402) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // failed, not updated
            else if (res.data.status === 403) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // login to access error message
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // validation error
            else if (res.data.status === 422) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
                setError(res.data.errors);
            }

            else {
                toast.info("Something went wrong! Try again");

            }
            setBtLoading(false);
        });

    }

    // check if user have profile image and show it else, show default one.
    const myphoto = (display_image.photo !== undefined && display_image.photo !== null) ?
        (display_image.uploadedImage ? display_image.photo : `https://remobileapi.zictech-ng.com/` + display_image.photo) : Image;
    // ends here

    // create a function to fetch total balance here
    const getUserDetails = () => {
        setIsLoading(true);
        // let create the api url here
        axios.get(`/api/fetch_users`).then(res => {
            if (res.data.status === 200) {
                setUserdetails(res.data.profile_details);
                setDisplay_image(res.data.profile_details);
            }
            // login required
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            else {
                toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });
            }
            setIsLoading(false);
        });
    }

    useEffect(() => {
        // call the function here
        getUserDetails();
        return () => {
        };
    }, []);

    // this is to set the show and hide switch button for profile image upload
    const [show, toggleShow] = React.useState(false);
    // ends here
    if (isLoading) {
        return (
            <div style={style}>
                <div className="spinner-border spinner-border-sm" role="status">
                </div> Loading
            </div>
        )
    }
    return (
        <>

            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Account Profile </h1>
                <Link to="/admin/dashboard" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"> <i className="fa fa-home"></i> Dashboard </Link>
            </div>


            <div className="container-xl px-4 mt-4">
                <div className="row">
                    <div className="col-xl-4">

                        <div className="card mb-4 mb-xl-0">
                            <div className="card-header">Profile Picture
                                <span className='float-end'>
                                    <div className="custom-control custom-switch">

                                        <input type="checkbox" onClick={() => toggleShow(!show)} className="custom-control-input" id="customSwitches" />
                                        <label className="custom-control-label" htmlFor="customSwitches"></label>
                                    </div>
                                </span>
                            </div>

                            <div className="card-body text-center">
                                <form onSubmit={updateProfileImage}>

                                    <img className="img-account-profile rounded-circle mb-2" src={myphoto}
                                        alt="profile_image" width={80} height={70} />
                                    <div>

                                        {show && <div>
                                            <input type='file' name='image' onChange={handleImage} className='form-control' />
                                            <div className="small font-italic text-muted mb-4">JPG or PNG no larger than 2 MB</div>
                                            <small className='text-danger'>{error_list.image}</small>
                                            <br />
                                            <div>
                                                <button type="submit" disabled={btLoading} className="btn btn-primary">
                                                    {btLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                    Update image
                                                </button>
                                            </div>
                                        </div>}
                                    </div>

                                </form>
                            </div>


                        </div>
                    </div>
                    <div className="col-xl-8">

                        <div className="card mb-4">
                            <div className="card-header">Account Details <span className='float-end'>Personal ID {userInput.mpcode}</span></div>
                            <div className="card-body">
                                <form onSubmit={updateProfile}>

                                    <div className="mb-3">
                                        <label className="small mb-1">Username (how your name will appear to other users on the site)</label>
                                        <input className="form-control" name='username' type="text" onChange={handleInput} value={userInput.username} placeholder="Enter your username" />
                                        <span className='text-danger'>{error_list.username}</span>
                                    </div>

                                    <div className="mb-3">


                                        <label className="small mb-1" >Full name</label>
                                        <input className="form-control" name='name' onChange={handleInput} value={userInput.name} type="text" placeholder="Full Name" />
                                        <span className='text-danger'>{error_list.name}</span>
                                    </div>

                                    <div className="row gx-3 mb-3">

                                        <div className="col-md-6">
                                            <label className="small mb-1" >Phone</label>
                                            <input className="form-control" name='phone' onChange={handleInput} value={userInput.phone} type="text" placeholder="Enter your phone" readOnly />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="small mb-1" >Email</label>
                                            <input className="form-control" name='email' onChange={handleInput} value={userInput.email} type="email" placeholder="Enter your email" />
                                            <span className='text-danger'>{error_list.email}</span>
                                        </div>
                                    </div>
                                    <div className="row gx-3 mb-3">
                                        <div className="col-md-6">
                                            <label className="small mb-1" >Sex</label>
                                            <input className="form-control" name='sex' onChange={handleInput} value={userInput.sex} type="text" placeholder="Enter sex" />
                                            <span className='text-danger'>{error_list.sex}</span>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="small mb-1" >Date of Birth</label>
                                            <input className="form-control" name='dob' onChange={handleInput} value={userInput.dob} type="date" placeholder="Enter your date of birth" />
                                            <span className='text-danger'>{error_list.dob}</span>
                                        </div>

                                    </div>
                                    <div className="row gx-3 mb-3">
                                        <div className="col-md-6">
                                            <label className="small mb-1">State</label>
                                            <input className="form-control" name='state' onChange={handleInput} value={userInput.state} type="text" placeholder="Enter your state" />
                                            <span className='text-danger'>{error_list.state}</span>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="small mb-1" >Location</label>
                                            <input className="form-control" name='location' onChange={handleInput} value={userInput.location} type="text" placeholder="Enter your location" />
                                            <span className='text-danger'>{error_list.location}</span>
                                        </div>
                                    </div>

                                    <div className="row gx-3 mb-3">
                                        <div className="col-md-6">
                                            <label className="small mb-1">Occupation</label>
                                            <input className="form-control" name='occupation' onChange={handleInput} value={userInput.occupation} type="text" placeholder="Enter your occupation" />
                                            <span className='text-danger'>{error_list.occupation}</span>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="small mb-1" >Marriage Status</label>
                                            <input className="form-control" name='married_status' onChange={handleInput} value={userInput.married_status} type="text" placeholder="Enter your marriage status" />
                                            <span className='text-danger'>{error_list.married_status}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="small mb-1">Address</label>
                                        <textarea name="address" onChange={handleInput} value={userInput.address} className="form-control form-control-user" placeholder="Address"></textarea>
                                    </div>

                                    <div className="card-header">Account Details</div>
                                    <hr />
                                    <div className="row gx-3 mb-3">

                                        <div className="col-md-6">
                                            <label className="small mb-1">Account Number</label>
                                            <input className="form-control" name='acctno' onChange={handleInput} value={userInput.acctno} type="text" placeholder="Enter your account number" />
                                            <span className='text-danger'>{error_list.acctno}</span>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="small mb-1" >Account Name</label>
                                            <input className="form-control" name='acct_name' onChange={handleInput} value={userInput.acct_name} type="text" placeholder="Enter your account name" />
                                            <span className='text-danger'>{error_list.acct_name}</span>
                                        </div>
                                    </div>

                                    <div className="row gx-3 mb-3">

                                        <div className="col-md-6">
                                            <label className="small mb-1">Bank Name</label>
                                            <input className="form-control" name='bankname' onChange={handleInput} value={userInput.bankname} type="text" placeholder="Enter your bank name" />
                                            <span className='text-danger'>{error_list.bankname}</span>
                                        </div>

                                    </div>

                                    <button type="submit" disabled={Loading} className="btn btn-primary" >
                                        {Loading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        Save changes
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Profile