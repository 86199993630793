import Dashboard from "../components/admin/Dashboard";
import Profile from "../components/admin/Profile";
import AddCoupon from "../components/admin/AddCoupon";
import TransferFunds from "../components/admin/TransferFunds";
import CashOut from "../components/admin/CashOut";
import Wallet from "../components/admin/Wallet";
import History from "../components/admin/History";
import Setting from "../components/admin/Setting";
import UpdatePassword from "../components/admin/UpdatePassword";

import Ticket from "../components/admin/Ticket";

const routes = [
    { path: '/admin', exact: true, name: 'Admin' },
    { path: '/admin/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/admin/profile', exact: true, name: 'Profile', component: Profile },
    { path: '/admin/add-coupon', exact: true, name: 'AddCoupon', component: AddCoupon },
    { path: '/admin/transfer-fund', exact: true, name: 'TransferFunds', component: TransferFunds },
    { path: '/admin/cash-out', exact: true, name: 'CashOut', component: CashOut },
    { path: '/admin/wallet', exact: true, name: 'Wallet', component: Wallet },
    { path: '/admin/history', exact: true, name: 'History', component: History },
    { path: '/admin/setting', exact: true, name: 'Setting', component: Setting },
    { path: '/admin/update-password', exact: true, name: 'UpdatePassword', component: UpdatePassword },
    { path: '/admin/ticket', exact: true, name: 'Ticket', component: Ticket },



]


export default routes;