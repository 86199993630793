import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import MasterLayout from "./layouts/admin/MasterLayout";
import Login from "./components/frontend/auth/Login";
import Register from "./components/frontend/auth/Register";
import Signup from "./components/frontend/auth/Signup";
import axios from 'axios';
//import PublicRoute from "./PublicRoute";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from "./ProtectedRoute";


axios.defaults.withCredentials = true;


//this declear base url globally for the application to access
axios.defaults.baseURL = "https://remobileapi.zictech-ng.com/";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['accept'] = 'application-json';
// ends here
axios.defaults.withCredentials = true;

/* this for setting token for logged in user to call when logging out */
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>

          <Route exact path="/" component={Login} />

          {/* <PublicRoute path="/" name="Login" /> */}

          {/* this will protect the route not to show if user have logged in already */}
          <Route path="/login">
            {localStorage.getItem('auth_token') ? <Redirect to='/admin/dashboard' /> : <Login />}
          </Route>



          <Route path="/signup">
            {localStorage.getItem('auth_token') ? <Redirect to='/admin/dashboard' /> : <Signup />}
          </Route>

          <Route path="/register">
            {localStorage.getItem('auth_token') ? <Redirect to='/admin/dashboard' /> : <Register />}
          </Route>


          {/* Ends here  */}
          <ProtectedRoute>
            <Route path="/admin" name="Admin" render={(props) =>
              <MasterLayout {...props} />

            } />
          </ProtectedRoute>


        </Switch>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

      </Router>
    </div>
  );
}

export default App;
