
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';


function Setting() {
    document.title = "Settings | Mobile Voucher"; // this will show the page title name
    //let set the input veriable details here

    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [loading, setLoading] = useState(true);
    // declear input handling function here for checkboxes
    const [allcheckbox, setCheckboxes] = useState([]);
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

    const handleCheckbox = (e) => {
        setCheckboxes({ ...allcheckbox, [e.target.name]: e.target.checked });
    }
    // let create function to send request to save the data via a api url
    const submitSetting = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            topup_email: allcheckbox.topup_email ? '1' : '0',
            debit_email: allcheckbox.debit_email ? '1' : '0',
            login_email: allcheckbox.login_email ? '1' : '0',
            credit_email: allcheckbox.credit_email ? '1' : '0',
            system_update: allcheckbox.system_update ? '1' : '0',
            promo_email: allcheckbox.promo_email ? '1' : '0',

        }
        // let create the api url here
        axios.post(`/api/save_setting1`, data).then(res => {
            if (res.data.status === 200) {
                // successful message
                toast.success(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // empty post sending error
            else if (res.data.status === 422) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // operation failed while trying to update setting! Try again
            else if (res.data.status === 502) {
                toast.warning(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // operation failed while trying to save setting! Try again
            else if (res.data.status === 501) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }

            // login required
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            else {
                toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });
            }
            setIsLoading(false);
        });
    }


    // let create function to send request to save the data via a api url
    const submitSetting2 = (e) => {
        e.preventDefault();
        setIsLoading2(true);
        const data_setting = {
            fa2_email: allcheckbox.fa2_email ? '1' : '0',
            otp_email: allcheckbox.otp_email ? '1' : '0',
        }
        // let create the api url here
        axios.post(`/api/save_setting2`, data_setting).then(res => {
            if (res.data.status === 200) {
                // successful message
                toast.success(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // empty post sending error
            else if (res.data.status === 422) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // operation failed while trying to update setting! Try again
            else if (res.data.status === 502) {
                toast.warning(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // operation failed while trying to save setting! Try again
            else if (res.data.status === 501) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }

            // login required
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            else {
                toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });
            }
            setIsLoading2(false);
        });
    }

    // create a function to fetch setting details here

    useEffect(() => {
        // call the function here
        // let create the api url here
        axios.get(`/api/fetch_settings`).then(res => {
            if (res.data.status === 200) {
                setCheckboxes(res.data.my_setting);
                //console.log(res.data.my_setting);
            }
            // login required
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // record not found
            setLoading(false);
        });
        return () => {
        };
    }, []);

    if (loading) {
        return (
            <div style={style}>
                <div className="spinner-border spinner-border-sm" role="status">
                </div> Loading
            </div>
        )
    }
    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Account Setting</h1>
                <Link to="/admin/dashboard" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"> <i className="fa fa-home"></i> Dashboard </Link>
            </div>

            <div className="col-lg-8">
                <div className="card card-header-actions mb-4">
                    <div className="card-header">
                        Email Notifications
                        {/* <div className="form-check form-switch">
                            <input className="form-check-input" id="flexSwitchCheckChecked" type="checkbox" checked="" />
                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                        </div> */}
                    </div>
                    <div className="card-body">
                        <form onSubmit={submitSetting}>
                            <div className="mb-0">
                                <label className="small mb-2">Now you can manage, enhance the functionalities of your account.</label>

                                {/* <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitches" />
                                    <label class="custom-control-label" htmlFor="customSwitches">Toggle this switch element</label>
                                </div>

                                <div className="custom-control custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="customSwitches1" />
                                    <label class="custom-control-label" htmlFor="customSwitches1">Toggle this switch element</label>
                                </div> */}
                                <div className="form-check mb-2">
                                    <input className="form-check-input" name='topup_email' type="checkbox" onChange={handleCheckbox} defaultChecked={allcheckbox.topup_email === '1' ? true : false} />
                                    <label className="form-check-label">Top-up Email Alert</label>
                                </div>

                                <div className="form-check mb-2">
                                    <input className="form-check-input" name='debit_email' onChange={handleCheckbox} defaultChecked={allcheckbox.debit_email === '1' ? true : false} type="checkbox" />
                                    <label className="form-check-label">Debit Email Alert</label>
                                </div>

                                <div className="form-check mb-2">
                                    <input className="form-check-input" name='login_email' onChange={handleCheckbox} defaultChecked={allcheckbox.login_email === '1' ? true : false} type="checkbox" />
                                    <label className="form-check-label">Account Login Email Alert</label>
                                </div>

                                <div className="form-check mb-2">
                                    <input className="form-check-input" name='credit_email' onChange={handleCheckbox} defaultChecked={allcheckbox.credit_email === '1' ? true : false} type="checkbox" />
                                    <label className="form-check-label">Transfer/Credit Email Alert</label>
                                </div>

                                <div className="form-check mb-2">
                                    <input className="form-check-input" name='promo_email' onChange={handleCheckbox} defaultChecked={allcheckbox.promo_email === '1' ? true : false} type="checkbox" />
                                    <label className="form-check-label">Promotional Email Alert</label>
                                </div>

                                <div className="form-check mb-2">
                                    <input className="form-check-input" name='system_update' onChange={handleCheckbox} defaultChecked={allcheckbox.system_update === '1' ? true : false} type="checkbox" />
                                    <label className="form-check-label">System Update Email Alert</label>
                                </div>
                                <button type="submit" disabled={isLoading} className="btn btn-primary float-end">
                                    {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="card card-header-actions mb-4">
                    <div className="card-header">
                        System Security (2FA)

                    </div>
                    <div className="card-body">
                        <form onSubmit={submitSetting2}>
                            <div className="mb-0">
                                <label className="small mb-2">Now you can manage and secured your account even more.</label>
                                {/* <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitches" />
                                    <label class="custom-control-label" htmlFor="customSwitches">Toggle this switch element</label>
                                </div>

                                <div className="custom-control custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="customSwitches1" />
                                    <label class="custom-control-label" htmlFor="customSwitches1">Toggle this switch element</label>
                                </div> */}
                                <div className="form-check mb-2">
                                    <input className="form-check-input" name='fa2_email' type="checkbox" onChange={handleCheckbox} defaultChecked={allcheckbox.fa2_email === '1' ? true : false} />
                                    <label className="form-check-label"> Activate 2FA</label>
                                </div>

                                <div className="form-check mb-2">
                                    <input className="form-check-input" name='otp_email' type="checkbox" onChange={handleCheckbox} defaultChecked={allcheckbox.otp_email === '1' ? true : false} />
                                    <label className="form-check-label"> Activate Transfer OTP</label>
                                </div>

                                <button type="submit" disabled={isLoading2} className="btn btn-primary float-end">
                                    {isLoading2 && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </>

    )
}

export default Setting