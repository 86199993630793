
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import { UserContext } from '../../context/UserContext';

function Ticket() {
    document.title = "Ticket | Mobile Voucher"; // this will show the page title name
    //let set the input veriable details here
    const [ticketInput, setTicket] = useState({
        title: '',
        message: '',
        subject: '',
        error_list: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useContext(UserContext);
    //const [logged_status, setLoggedStatus] = loggin_status;
    const [user_details] = user;

    // declear input handling function here
    const handleInput = (e) => {
        e.persist();
        setTicket({ ...ticketInput, [e.target.name]: e.target.value })
    }
    // let create function to send request to save the data via a api url
    const submitTicket = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            subject: ticketInput.subject,
            message: ticketInput.message,
            title: ticketInput.title
        }

        // let create the api url here
        axios.post(`/api/send_ticket`, data).then(res => {
            if (res.data.status === 200) {
                // successful message
                toast.success(res.data.message, { position: 'top-center', theme: 'colored' });
                setTicket({
                    ...ticketInput,
                    subject: '',
                    message: '',
                    title: '',
                });

            }
            // data input required
            else if (res.data.status === 422) {
                toast.warning('Missing Data Required', { position: 'top-center', theme: 'colored' });
                setTicket({ ...ticketInput, error_list: res.data.errors });
            }

            // operation failed while trying to send funds! Try again
            else if (res.data.status === 501) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }

            // login required
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            else {
                toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });
            }
            setIsLoading(false);
        });

    }
    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Ticket</h1>
                <Link to="/admin/dashboard" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"> <i className="fa fa-home"></i> Dashboard </Link>
            </div>
            <div className="row">
                <div className="col-lg-6 mb-4">

                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary"> Do you have any issues? Submit Ticket</h6>
                        </div>
                        <div className="card-body">
                            <form onSubmit={submitTicket} className="user">

                                <div className="mb-3">
                                    <label className="small mb-1">Ticket Subject</label>
                                    <select name="subject" onChange={handleInput} value={ticketInput.subject} className="form-control">
                                        <option></option>
                                        <option value="Fund Transfer"> Fund Transfer</option>
                                        <option value="Account Limit"> Account Limit</option>
                                        <option value="Account Deactivation"> Account Deactivation</option>
                                        <option value="Account Funding"> Account Funding</option>
                                        <option value="Invalid Coupon"> Invalid Coupon</option>
                                        <option value="System Error"> System Error</option>
                                        <option value="Others"> Others</option>
                                    </select>
                                    <span className='text-danger'>{ticketInput.error_list.subject}</span>
                                </div>

                                <div className="mb-3">
                                    <label className="small mb-1">Ticket Title</label>
                                    <input className="form-control" name='title' onChange={handleInput} value={ticketInput.title} type="text" placeholder="Enter Title" />
                                    <span className='text-danger'>{ticketInput.error_list.title}</span>
                                </div>

                                <div className="mb-3">
                                    <label className="small mb-1">message</label>
                                    <textarea name="message" onChange={handleInput} value={ticketInput.message} className="form-control" placeholder="Message"></textarea>
                                    <span className='text-danger'>{ticketInput.error_list.message}</span>
                                </div>

                                <hr />
                                <button type="submit" disabled={isLoading} className="btn btn-primary">
                                    {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Send
                                </button>

                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Ticket;