
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';

function UpdatePassword() {

    document.title = "Update Password | Mobile Voucher"; // this will show the page title name
    const [isLoading, setIsLoading] = useState(false);
    //let set the input veriable details here
    const [passwordInput, setPassword] = useState({
        new_password: '',
        password_confirmation: '',
        error_list: [],
    });

    // declear input handling function here
    const handleInput = (e) => {
        e.persist();
        setPassword({ ...passwordInput, [e.target.name]: e.target.value })
    }
    const updateUserPassword = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            new_password: passwordInput.new_password,
            password_confirmation: passwordInput.password_confirmation,
        }

        // let create the api url here
        axios.post(`/api/update_password`, data).then(res => {
            if (res.data.status === 200) {
                // successful message
                toast.success(res.data.message, { position: 'top-center', theme: 'colored' });
                setPassword({
                    ...passwordInput,
                    new_password: '',
                    password_confirmation: '',

                });

            }
            // data input required
            else if (res.data.status === 422) {
                toast.warning('Missing Data Required', { position: 'top-center', theme: 'colored' });
                setPassword({ ...passwordInput, error_list: res.data.errors });
            }
            // operation failed while trying to send funds! Try again
            else if (res.data.status === 501) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // Password not matched
            else if (res.data.status === 403) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }

            // login required
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            else {
                toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });
            }
            setIsLoading(false);
        });

    }
    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Password Update</h1>
                <Link to="/admin/dashboard" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"> <i className="fa fa-home"></i> Dashboard </Link>
            </div>

            <div className="col-lg-8">
                <div className="card card-header-actions mb-4">
                    <div className="card-header">
                        Update your password
                    </div>
                    <div className="card-body">
                        <form onSubmit={updateUserPassword} >
                            <div className="mb-0">
                                <label className="small mb-2">Regular update recommended to secure your account.</label>



                                <div className="form-check mb-2">
                                    <input type="password" name="new_password" onChange={handleInput} value={passwordInput.new_password} className="form-control form-control-user"
                                        placeholder="Enter New Password" />
                                    <span className='text-danger'>{passwordInput.error_list.new_password}</span>
                                </div>

                                <div className="form-check mb-2">
                                    <input type="password" name="password_confirmation" onChange={handleInput} value={passwordInput.password_confirmation} className="form-control form-control-user"
                                        placeholder="Enter Confirm Password" />
                                    <span className='text-danger'>{passwordInput.error_list.password_confirmation}</span>
                                </div>

                                <button type="submit" disabled={isLoading} className="btn btn-primary float-end">
                                    {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Update Password
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdatePassword
