import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
function Signup() {

    const history = useHistory();
    const [signupInput, setSignup] = useState({
        /* declear veriable */
        fname: '',
        lname: '',
        email: '',
        password: '',
        phone: '',
        error_list: [],
    });
    /* set/get the value from the input flieds */
    const handleInput = (e) => {
        e.persist();
        setSignup({ ...signupInput, [e.target.name]: e.target.value });
    }
    /* create login function to send request to api here */
    const signupSubmit = (e) => {
        try {      // Do something that could throw 

            e.preventDefault();
            /*collect the input data entered by user here*/
            const data = {
                fname: signupInput.fname,
                lname: signupInput.lname,
                email: signupInput.email,
                phone: signupInput.phone,
                password: signupInput.password,
            }
            /* send request to api using axios here*/
            /* this below axios.get is use to generate csrtoken */
            axios.get('/sanctum/csrf-cookie').then(response => {
                axios.post(`/api/signup`, data).then(res => {
                    if (res.data.status === 200) {
                        localStorage.setItem('auth_token', res.data.token);
                        localStorage.setItem('auth_name', res.data.username);
                        toast.success("Success! " + res.data.message);
                        //swal("Success!", res.data.message, "success");
                        if (res.data.role === 1) {
                            history.push('/admin/dashboard');
                        }
                        else {
                            history.push('/');
                        }

                    }
                    else if (res.data.status === 401) {
                        toast.warning("Sorry! " + res.data.message);
                        //swal("Warning!", res.data.message, "warning");
                    }
                    else {
                        /* show/get errors message if user didn't fill the fields here */
                        setSignup({ ...signupInput, error_list: res.data.validation_errors });
                        toast.error("Required fields are missing", {
                            theme: "colored"
                        });
                    }
                });
            });
        } catch (error) {
            this.setState({ error });
        }

    }
    return (
        <>
            <div className="bg-gradient-primary">
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-xl-10 col-lg-12 col-md-9">

                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">

                                    <div className="row">
                                        <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                        <div className="col-lg-6">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                                </div>
                                                <form onSubmit={signupSubmit} className="user">
                                                    <div className="form-group row">
                                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                                            <input type="text" name="fname" onChange={handleInput} value={signupInput.fname} className="form-control form-control-user"
                                                                placeholder="First Name" />
                                                            <span className='text-danger'>{signupInput.error_list.fname}</span>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <input type="text" name="lname" onChange={handleInput} value={signupInput.lname} className="form-control form-control-user"
                                                                placeholder="Last Name" />
                                                            <span className='text-danger'>{signupInput.error_list.lname}</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="email" name="email" onChange={handleInput} value={signupInput.email} className="form-control form-control-user"
                                                            placeholder="Email Address" />
                                                        <span className='text-danger'>{signupInput.error_list.email}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" name="phone" onChange={handleInput} value={signupInput.phone} className="form-control form-control-user"
                                                            placeholder="Phone" />
                                                        <span className='text-danger'>{signupInput.error_list.phone}</span>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-sm-12 mb-3 mb-sm-0">
                                                            <input type="password" name="password" onChange={handleInput} value={signupInput.password} className="form-control form-control-user"
                                                                id="exampleInputPassword" placeholder="Password" />
                                                            <span className='text-danger'>{signupInput.error_list.password}</span>
                                                        </div>
                                                        {/* <div className="col-sm-6">
                                                            <input type="password" className="form-control form-control-user"
                                                                id="exampleRepeatPassword" placeholder="Repeat Password" />
                                                        </div> */}
                                                    </div>
                                                    <button type="submit" className="btn btn-primary btn-user btn-block">
                                                        Register Account
                                                    </button>
                                                    <hr />
                                                    <Link to="#" className="btn btn-google btn-user btn-block">
                                                        <i className="fab fa-google fa-fw"></i> Register with Google
                                                    </Link>
                                                    <Link to="#" className="btn btn-facebook btn-user btn-block">
                                                        <i className="fab fa-facebook-f fa-fw"></i> Register with Facebook
                                                    </Link>


                                                    {/*                                                     
                                                    
                                                    <div className="form-group">
                                                        <input type="email" name="email" onChange={handleInput} value={signupInput.email} className="form-control form-control-user"
                                                            id="exampleInputEmail" placeholder="Enter Email Address..." />
                                                        <span className='text-danger'>{signupInput.error_list.email}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" name="password" onChange={handleInput} value={signupInput.email} className="form-control form-control-user"
                                                            id="exampleInputPassword" placeholder="Password" />
                                                        <span className='text-danger'>{signupInput.error_list.password}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="custom-control custom-checkbox small">
                                                            <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                            <label className="custom-control-label" for="customCheck">Remember
                                                                Me</label>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary btn-user btn-block">
                                                        Login
                                                    </button>
                                                    <hr />
                                                    <Link to="#" className="btn btn-google btn-user btn-block">
                                                        <i className="fab fa-google fa-fw"></i> Login with Google
                                                    </Link>
                                                    <Link to="#" className="btn btn-facebook btn-user btn-block">
                                                        <i className="fab fa-facebook-f fa-fw"></i> Login with Facebook
                                                    </Link> */}
                                                </form>
                                                <hr />
                                                <div className="text-center">
                                                    <Link className="small" to="forgot-password">Forgot Password?</Link>
                                                </div>
                                                <div className="text-center">
                                                    <Link className="small" to="/register">Create an Account!</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}
export default Signup;