
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

function Register() {
    document.title = "Register | Mobile Voucher"; // this will show the page title name
    const history = useHistory();
    const [registerInput, setRegister] = useState({
        // declear all input veriable here 
        fname: '',
        lname: '',
        email: '',
        password: '',
        phone: '',
        error_list: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    // set the input veriable from the handleinput event
    const handleInput = (e) => {
        e.persist();
        setRegister({ ...registerInput, [e.target.name]: e.target.value });
    }
    // get the form submit event here / bind the input data to the veriable names
    const registerUser = (e) => {
        e.preventDefault();
        setIsLoading(true);
        // declear veriable to hold all input data here
        const data = {
            fname: registerInput.fname,
            lname: registerInput.lname,
            email: registerInput.email,
            phone: registerInput.phone,
            password: registerInput.password,
        }
        // send the request to back with axios
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/register`, data).then(res => {
                if (res.data.status === 200) {
                    // localStorage.setItem('auth_token', res.data.token);
                    // localStorage.setItem('auth_name', res.data.username);
                    toast.success(res.data.message);
                    //swal("Success!", res.data.message, "success");
                    /* redirect back home after successful registration */
                    history.push('/login');
                }
                else if (res.status === 401) {
                    toast.error(res.data.message, {
                        theme: "colored"
                    });

                }
                else {
                    setRegister({ ...registerInput, error_list: res.data.validation_errors });
                    toast.error("Required fields are missing", {
                        theme: "colored"
                    });
                }
            });
            setIsLoading(false);
        });
    }

    return (
        <>
            <div className="bg-gradient-primary">
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-xl-10 col-lg-12 col-md-9">

                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">

                                    <div className="row">
                                        <div className="col-lg-5 d-none d-lg-block bg-register-image"></div>
                                        <div className="col-lg-7">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4">Create an Account!</h1>
                                                </div>
                                                <form onSubmit={registerUser} className="user">
                                                    <div className="form-group row">
                                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                                            <input type="text" name="fname" onChange={handleInput} value={registerInput.fname} className="form-control form-control-user"
                                                                placeholder="First Name" />
                                                            <span className='text-danger'>{registerInput.error_list.fname}</span>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <input type="text" name="lname" onChange={handleInput} value={registerInput.lname} className="form-control form-control-user"
                                                                placeholder="Last Name" />
                                                            <span className='text-danger'>{registerInput.error_list.lname}</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="email" name="email" onChange={handleInput} value={registerInput.email} className="form-control form-control-user"
                                                            placeholder="Email Address" />
                                                        <span className='text-danger'>{registerInput.error_list.email}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" name="phone" onChange={handleInput} value={registerInput.phone} className="form-control form-control-user"
                                                            placeholder="Phone" />
                                                        <span className='text-danger'>{registerInput.error_list.phone}</span>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-sm-12 mb-3 mb-sm-0">
                                                            <input type="password" name="password" onChange={handleInput} value={registerInput.password} className="form-control form-control-user"
                                                                id="exampleInputPassword" placeholder="Password" />
                                                            <span className='text-danger'>{registerInput.error_list.password}</span>
                                                        </div>
                                                        {/* <div className="col-sm-6">
                                                            <input type="password" className="form-control form-control-user"
                                                                id="exampleRepeatPassword" placeholder="Repeat Password" />
                                                        </div> */}
                                                    </div>
                                                    <button type="submit" disabled={isLoading} className="btn btn-primary btn-user btn-block">
                                                        {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                        Register Account
                                                    </button>
                                                    <hr />
                                                    <Link to="#" className="btn btn-google btn-user btn-block">
                                                        <i className="fab fa-google fa-fw"></i> Register with Google
                                                    </Link>
                                                    <Link to="#" className="btn btn-facebook btn-user btn-block">
                                                        <i className="fab fa-facebook-f fa-fw"></i> Register with Facebook
                                                    </Link>
                                                </form>
                                                <hr />
                                                <div className="text-center">
                                                    <Link className="small" to="#">Forgot Password?</Link>
                                                </div>
                                                <div className="text-center">
                                                    <Link className="small" to="/login">Already have an account? Login!</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </>
    )
}
export default Register;