import axios from "axios";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";


function Sidebar() {

    const history = useHistory();
    /* logout function goes here */
    const logoutSubmit = (e) => {
        e.preventDefault();
        axios.post(`/api/logout`).then(res => {

            /* check if logout is successful and clear all data store */
            if (res.data.status === 200) {
                localStorage.removeItem('auth_token');
                localStorage.removeItem('auth_name');
                localStorage.removeItem('auth_email');
                //localStorage.removeItem('Auth_logged');
                toast.success(res.data.message);
                history.push('../login');

            }
            else if (res.data.status === 401) {
                toast.error(res.data.message);
            }
        });
    }

    return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/admin">
                <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-mobile"></i>
                </div>
                <div className="sidebar-brand-text mx-6">Mobile Voucher System</div>
            </Link>

            <hr className="sidebar-divider my-0" />

            <li className="nav-item active">
                <Link className="nav-link" to="/admin">
                    <i className="fa fa-dashboard"></i>
                    <span>Dashboard</span></Link>
            </li>
            <hr className="sidebar-divider" />

            <div className="sidebar-heading">
                Transaction
            </div>

            <li className="nav-item">
                <Link className="nav-link" to="/admin/add-coupon">
                    <i className="fa fa-plus-circle"></i>
                    <span>Add Coupon</span></Link>
            </li>

            <li className="nav-item">
                <Link className="nav-link" to="/admin/transfer-fund">
                    <i className="fa fa-exchange"></i>
                    <span>Transfer</span></Link>
            </li>

            <li className="nav-item">
                <Link className="nav-link" to="/admin/cash-out">
                    <i className="fa fa-money"></i>
                    <span>Cash Out</span></Link>
            </li>
            <div className="sidebar-heading">
                Account
            </div>

            <li className="nav-item">
                <Link className="nav-link" to="/admin/wallet">
                    <i className="fas fa-fw fa-wallet"></i>
                    <span>Wallet</span></Link>
            </li>

            <li className="nav-item">
                <Link className="nav-link" to="/admin/history">
                    <i className="fas fa-fw fa-history"></i>
                    <span>History</span></Link>
            </li>

            <div className="sidebar-heading">
                Security
            </div>

            <li className="nav-item">
                <Link className="nav-link" to="/admin/setting">
                    <i className="fa fa-fw fa-cog"></i>
                    <span>Account Setting</span></Link>
            </li>

            <div className="sidebar-heading">
                Data
            </div>

            <li className="nav-item">
                <Link className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <i className="fas fa-fw fa-user"></i>
                    <span>Profile Details</span>
                </Link>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">My Profile:</h6>
                        <Link className="collapse-item" to="/admin/profile">View Profile</Link>

                        <Link className="collapse-item" to="/admin/update-password">Update Password</Link>
                    </div>
                </div>
            </li>

            <li className="nav-item">
                <Link className="nav-link" to="/admin/ticket">
                    <i className="fas fa-fw fa-ticket-alt"></i>
                    <span>Ticket</span></Link>
            </li>

            {/* <li className="nav-item">
                <Link className="nav-link" to="/admin/profile">
                    <i className="fas fa-fw fa-chart-area"></i>
                    <span>Profile</span></Link>
            </li> */}

            <hr className="sidebar-divider" />

            <div className="sidebar-heading">
                Action
            </div>

            <li className="nav-item">
                <Link className="nav-link" to="#">
                    <i className="fas fa-fw fa-power-off"></i>
                    <span onClick={logoutSubmit}>Logout</span></Link>
            </li>
        </ul>
    );
};

export default Sidebar;