
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

function Wallet() {
    // use history function here
    //const history = useHistory();
    document.title = "Wallet | Mobile Voucher"; // this will show the page title name
    const [wallet_balance, setWalletbalance] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    //let set the input veriable details here
    const [cashoutInput, setCashout] = useState({
        send_amount: '',
        send_note: '',
        error_list: [],
    });

    const [nowLoading, setNowLoading] = useState(false);

    // declear input handling function here
    const handleInput = (e) => {
        e.persist();
        setCashout({ ...cashoutInput, [e.target.name]: e.target.value })
    }
    // let create function to send request to save the data via a api url
    const submitCashout = (e) => {
        e.preventDefault();
        setNowLoading(true);
        const data = {
            send_amount: cashoutInput.send_amount,
            send_note: cashoutInput.send_note
        }

        // let create the api url here
        axios.post(`/api/cash_out`, data).then(res => {
            if (res.data.status === 200) {
                // successful message
                toast.success(res.data.message, { position: 'top-center', theme: 'colored' });
                setCashout({
                    ...cashoutInput,
                    send_amount: '',
                    send_note: '',
                });
                // call back the get balance function here
                myBalance();

                // document.getElementById("wallet_cashout").classList.remove("show");
                // document.querySelectorAll(".modal-backdrop")
                //     .forEach(el => el.classList.remove("modal-backdrop"));
                // history.push('/admin/dashboard');

            }
            // data input required
            else if (res.data.status === 422) {
                toast.warning('Missing Data Required', { position: 'top-center', theme: 'colored' });
                setCashout({ ...cashoutInput, error_list: res.data.errors });
            }

            // error low wallet balance
            else if (res.data.status === 403) {
                toast.warning(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // operation failed while trying to send funds! Try again
            else if (res.data.status === 501) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }

            // login required
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            else {
                toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });
            }
            setNowLoading(false);
        });
    }
    // create a function to fetch total balance here
    const myBalance = (e) => {
        setIsLoading(true);
        // let create the api url here
        axios.get(`/api/fetch_wallet`).then(res => {
            if (res.data.status === 200) {
                setWalletbalance(res.data.wallet);

            }
            // login required
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            else {
                toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });
            }
            setIsLoading(false);
        });
    }
    useEffect(() => {
        // call the function here
        myBalance();
        return () => {

        };
    }, []);

    return (

        <>
            <br /><br /><br />
            <div className="row">

                <div className="col-lg-6 mb-4">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Wallet Balance</h6>
                        </div>
                        <div className="card-body bg-success text-white shadow">
                            <div className="text-center">

                                <div className="row py-6 ">
                                    <div className="svg vis">

                                        <h1>{isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            <i className="fas fa-dollar-sign text-gray-300"></i>
                                            <strong>{(wallet_balance).toLocaleString(undefined, { maximumFractionDigits: 2 })}</strong>

                                        </h1>

                                    </div>

                                </div>

                            </div>
                            <p>Account main balance details <Link
                                rel="nofollow" to="/admin/history"><strong>View Details</strong></Link> for complete transaction</p>
                            <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#wallet_cashout">
                                Cash Out
                            </button>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 mb-4">

                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Account Details</h6>
                        </div>

                        <div className="card-body">
                            <h5 className="small">All transactions in the account required to maintain the following limit</h5>

                            <h4 className="small font-weight-bold">Inflow <span
                                className="float-right">100%</span></h4>
                            <div className="progress mb-4">
                                <div className="progress-bar bg-success" role="progressbar"
                                    style={{ width: "100%" }}

                                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <h4 className="small font-weight-bold">Balance <span
                                className="float-right">100%</span></h4>
                            <div className="progress mb-4">
                                <div className="progress-bar bg-primary" role="progressbar"
                                    style={{ width: "100%" }}
                                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <h4 className="small font-weight-bold">Outflow <span
                                className="float-right">50%</span></h4>
                            <div className="progress mb-4">
                                <div className="progress-bar bg-warning" role="progressbar"
                                    style={{ width: "50%" }}
                                    aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>

                            <h4 className="small font-weight-bold">Loan<span
                                className="float-right">15%</span></h4>
                            <div className="progress">
                                <div className="progress-bar bg-secondary" role="progressbar"
                                    style={{ width: "15%" }}
                                    aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="modal fade" id="wallet_cashout" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="wallet_cashoutLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="wallet_cashoutLabel">Cash Out</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={submitCashout}>
                            <div className="modal-body">

                                <div className="form-group">
                                    <input type="text" name="send_amount" onChange={handleInput} value={cashoutInput.send_amount} className="form-control form-control-user"
                                        placeholder="Enter Amount (ex: 10)" />
                                    <span className='text-danger'>{cashoutInput.error_list.send_amount}</span>
                                    {/* <span className='text-danger'>{loginInput.error_list.email}</span> */}
                                </div>
                                <div className="form-group">
                                    <textarea name="send_note" onChange={handleInput} value={cashoutInput.send_note} className="form-control form-control-user" placeholder="Short note to serve you better"></textarea>

                                    {/* <span className='text-danger'>{loginInput.error_list.email}</span> */}
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" disabled={nowLoading} className="btn btn-primary">
                                    {nowLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Wallet;