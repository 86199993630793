import React from 'react';
import '../../assets/admin/css/sb-admin-2.css';
import '../../assets/admin/css/sb-admin-2.min.css';
import '../../assets/admin/css/styles.css';
import '../../assets/admin/js/scripts.js';

import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { useHistory } from 'react-router-dom';

//import Dashboard from '../../components/admin/Dashboard';
import routes from '../../routes/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

//import { UserProvider } from '../../context/UserContext';

const MasterLayout = () => {
    const history = useHistory();

    // // check if user has token, else send them back to login
    // if (!localStorage.getItem('auth_token')) {
    //     history.push('/login');
    // }

    //const provideValue = useMemo(() => ({ userinfo, setUserInfo }), [userinfo, setUserInfo]);

    /* logout function goes here */
    const logoutSubmit = (e) => {
        e.preventDefault();
        axios.post(`/api/logout`).then(res => {

            /* check if logout is successful and clear all data store */
            if (res.data.status === 200) {
                localStorage.removeItem('auth_token');
                localStorage.removeItem('auth_name');
                localStorage.removeItem('auth_email');
                //localStorage.removeItem('Auth_logged');
                toast.success(res.data.message);
                document.getElementById("logoutModal").classList.remove("show");
                document.querySelectorAll(".modal-backdrop")
                    .forEach(el => el.classList.remove("modal-backdrop"));
                // history.push('/admin/dashboard');
                history.push('../login');

            }
            else if (res.data.status === 401) {
                toast.error(res.data.message);
            }
        });


    }
    return (
        <div id='page-top'>
            <div id="wrapper">

                <Sidebar>

                </Sidebar>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">
                            {/* <Dashboard /> */}
                            {/* this is the routes that display all pages base on the one been click on */}
                            <Switch>


                                {routes.map((route, idx) => {
                                    return (
                                        route.component && (
                                            <Route
                                                key={idx}
                                                path={route.path}
                                                exact={route.exact}
                                                name={route.name}
                                                render={(props) => (
                                                    <route.component{...props} />
                                                )}
                                            />
                                        )
                                    )
                                })}
                                <Redirect from='/admin' to="/admin/dashboard" />

                            </Switch>

                        </div>
                    </div>
                    <Footer></Footer>

                </div>

            </div>
            <div className="modal fade" id="logoutModal" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                            <button className="btn btn-danger" onClick={logoutSubmit}>Logout</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MasterLayout;