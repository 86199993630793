import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

import axios from 'axios';
import { useHistory } from 'react-router-dom';

//import AuthContext from "../../../context/AuthProvider";
import { UserContext } from '../../../context/UserContext';

function Login() {
    document.title = "Login | Mobile Voucher"; // this will show the page title name
    const history = useHistory();

    //const value = useContext(UserContext);

    const [isLoggedIn, setisLoggedIn] = useState(null);
    const [loginInput, setLogin] = useState({
        /* declear veriable */
        email: '',
        password: '',
        error_list: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    //const [user, setUser] = useState({});
    const { loggin_status, user } = useContext(UserContext);
    const [logged_status, setLoggedStatus] = loggin_status;
    const [user_details, setUserDetails] = user;


    //const { setAuth } = useContext(AuthContext);

    //const [user_details, setUserDetails] = useContext(UserContext);

    /* set/get the value from the input flieds */
    const handleInput = (e) => {
        e.persist();
        setLogin({ ...loginInput, [e.target.name]: e.target.value });
    }

    /* create login function to send request to api here */
    const loginSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        /*collect the input data entered by user here*/
        const data = {
            email: loginInput.email,
            password: loginInput.password,
        }
        /* send request to api using axios here*/
        /* this below axios.get is use to generate csrtoken */
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/login`, data).then(res => {
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token);
                    localStorage.setItem('auth_name', res.data.username);
                    localStorage.setItem('auth_email', res.data.email);
                    localStorage.setItem("Auth_logged", "True");
                    // console.log(accessToken, username_email, roles);
                    setUserDetails(res.data.userDetails);
                    setisLoggedIn(true);
                    setLoggedStatus(true);
                    //console.log(res.data.username);

                    toast.success(res.data.message);
                    //swal("Success!", res.data.message, "success");
                    if (res.data.role === 'User') {
                        history.push('/admin/dashboard');
                    }
                    else if (res.data.role === 'Admin') {
                        history.push('/admin/admin');
                    }
                    else {
                        toast.warning("Sorry! Permission not found");
                    }
                }
                else if (res.data.status === 401) {
                    toast.warning("Sorry! " + res.data.message);
                    //swal("Warning!", res.data.message, "warning");
                    setIsLoading(false);
                }
                else {
                    /* show/get errors message if user didn't fill the fields here */
                    setLogin({ ...loginInput, error_list: res.data.validation_errors });
                    toast.error("Required fields are missing", {
                        theme: "colored"
                    });
                    setIsLoading(false);
                }
            }).catch(error => {
                console.log("Server Issues", error);
            });;
        });

    }
    return (
        <>
            <div className="bg-gradient-primary">
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-xl-10 col-lg-12 col-md-9">

                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">

                                    <div className="row">
                                        <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                        <div className="col-lg-6">
                                            <div className="p-5">
                                                <div className="text-center">

                                                    {isLoggedIn ? (
                                                        <h1 className="h4 text-gray-900 mb-4">
                                                            Welcome Back!
                                                        </h1>
                                                    ) : (
                                                        <h1 className="h4 text-gray-900 mb-4">
                                                            Login to Continue </h1>
                                                    )}

                                                </div>
                                                <form onSubmit={loginSubmit} className="user">
                                                    <div className="form-group">
                                                        <input type="email" name="email" onChange={handleInput} value={loginInput.email} className="form-control form-control-user"
                                                            id="exampleInputEmail" placeholder="Enter Email Address..." />
                                                        <span className='text-danger'>{loginInput.error_list.email}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" name="password" onChange={handleInput} value={loginInput.password} className="form-control form-control-user" placeholder="Password" />
                                                        <span className='text-danger'>{loginInput.error_list.password}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="custom-control custom-checkbox small">
                                                            <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                            <label className="custom-control-label">Remember
                                                                Me</label>
                                                        </div>
                                                    </div>
                                                    <button type="submit" disabled={isLoading} className="btn btn-primary btn-user btn-block">
                                                        {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}

                                                        Login
                                                    </button>
                                                    <hr />
                                                    <Link to="#" className="btn btn-google btn-user btn-block">
                                                        <i className="fab fa-google fa-fw"></i> Login with Google
                                                    </Link>
                                                    <Link to="#" className="btn btn-facebook btn-user btn-block">
                                                        <i className="fab fa-facebook-f fa-fw"></i> Login with Facebook
                                                    </Link>
                                                </form>
                                                <hr />
                                                <div className="text-center">
                                                    <Link className="small" to="#forgot-password">Forgot Password?</Link>
                                                </div>
                                                <div className="text-center">
                                                    <Link className="small" to="/register">Create an Account!</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}
export default Login;