
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Image from '../../assets/admin/img/avatar.png';
import { UserContext } from "../../context/UserContext";
const Navbar = () => {

  const [display_image, setDisplay_image] = useState([]);
  const [notification, setNotification] = useState([]);
  const { my_photo } = React.useContext(UserContext);
  const [photo_status, setPhtotStatus] = my_photo;
  //const [isLoading, setIsLoading] = useState(false);

  const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

  // get notification details here
  const getNotification = () => {

    axios.get(`/api/fetch_history_nav`).then(res => {
      if (res.data.status === 200) {
        setNotification(res.data.nav_record);
      }
      else if (res.data.status === 401) {
        toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
        //console.log(res.data.message);
      }
      else {
        toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });
      }
    }).catch(err => {
      console.log(err)
    })

  }
  var newStatus = '';
  // create a function to fetch total balance here
  const getUserDetails = () => {

    // let create the api url here
    axios.get(`/api/fetch_users`).then(res => {
      if (res.data.status === 200) {

        setDisplay_image(res.data.profile_details);
        setPhtotStatus(res.data.profile_details)
      }
      // login required
      else if (res.data.status === 401) {
        toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
      }
      else {
        toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });
      }
    });
  }

  useEffect(() => {
    // call the function here
    getUserDetails();
    getNotification();
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // check if user have profile image and show it else, show default one.
  // const myphoto = (display_image.photo !== undefined && display_image.photo !== null) ?
  //   `http://localhost:8000/` + display_image.photo : Image;

  // check if user have profile image and show it else, show default one.
  const myphoto = (photo_status.photo !== undefined && photo_status.photo !== null) ?
    (photo_status.uploadedImage ? photo_status.photo : `https://remobileapi.zictech-ng.com/` + photo_status.photo) : Image;

  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i className="fa fa-bars"></i>
        </button>

        <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
          <div className="input-group">
            <input
              type="text"
              className="form-control bg-light border-0 small"
              placeholder="Search for..."
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
            <div className="input-group-append">
              <button className="btn btn-primary" type="button">
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
        </form>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown no-arrow d-sm-none">
            <Link
              to="#"
              className="nav-link dropdown-toggle"
              id="searchDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-search fa-fw"></i>
            </Link>

            <div
              className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
              aria-labelledby="searchDropdown"
            >
              <form className="form-inline mr-auto w-100 navbar-search">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                      <i className="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>

          <li className="nav-item dropdown no-arrow mx-1">

            <Link
              to="#"
              className="nav-link dropdown-toggle"
              id="alertsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-bell fa-fw"></i>
              {
                notification.length > 0 ? <span className="badge badge-danger badge-counter">{notification.length}</span> : <span></span>
              }
            </Link>

            <div
              className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="alertsDropdown"
            >
              <h6 className="dropdown-header">Notifications Center</h6>
              {
                notification.length > 0 ?
                  <div>
                    {notification.slice(0, 5).map((item, i) => {
                      const udate = new Date((item.created_at));
                      //console.log(date.toLocaleDateString('en-GB', DATE_OPTIONS));
                      const create_date = (udate.toLocaleDateString('en-GB', DATE_OPTIONS));
                      if (item.status === 'Credit')// this mean product is active
                      {
                        newStatus = <div className="mr-3">
                          <div className="icon-circle bg-success">
                            <i className="fas fa-plus text-white"></i>
                          </div>
                        </div>
                      }
                      else if (item.status === 'Debit') {
                        newStatus = <div className="mr-3">
                          <div className="icon-circle bg-danger">
                            <i className="fas fa-arrow-down text-white"></i>
                          </div>
                        </div>
                      }
                      else {
                        newStatus = <div className="mr-3">
                          <div className="icon-circle bg-primary">
                            <i className="fas fa-file-alt text-white"></i>
                          </div>
                        </div>
                      }
                      return (
                        <Link to="/admin/history" key={i} className="dropdown-item d-flex align-items-center">
                          {newStatus}
                          <div>
                            <div className="small text-gray-500">{create_date}</div>
                            <span className="font-weight-bold">
                              {item.action_nature}
                            </span>
                          </div>
                        </Link>
                      )
                    })}
                    <Link
                      className="dropdown-item text-center small text-gray-500"
                      to="/admin/history">
                      Show All Notifications
                    </Link>
                  </div>
                  : <div>
                    <Link
                      className="dropdown-item text-center small text-gray-500"
                      to="#">
                      No Notifications
                    </Link>
                  </div>
              }
            </div>
          </li>

          {/* <li className="nav-item dropdown no-arrow mx-1">
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="messagesDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-envelope fa-fw"></i>

            <span className="badge badge-danger badge-counter">7</span>
          </Link>

          <div
            className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="messagesDropdown"
          >
            <h6 className="dropdown-header">Message Center</h6>
            <Link className="dropdown-item d-flex align-items-center" to="#">
              <div className="dropdown-list-image mr-3">
                <img
                  className="rounded-circle"
                  src="img/undraw_profile_1.svg"
                  alt="..."
                />
                <div className="status-indicator bg-success"></div>
              </div>
              <div className="font-weight-bold">
                <div className="text-truncate">
                  Hi there! I am wondering if you can help me with a problem
                  I've been having.
                </div>
                <div className="small text-gray-500">Emily Fowler · 58m</div>
              </div>
            </Link>
            <Link className="dropdown-item d-flex align-items-center" to="#">
              <div className="dropdown-list-image mr-3">
                <img
                  className="rounded-circle"
                  src="../../assets/admin/img/avatar.png"
                  alt="..."
                />
                <div className="status-indicator"></div>
              </div>
              <div>
                <div className="text-truncate">
                  I have the photos that you ordered last month, how would you
                  like them sent to you?
                </div>
                <div className="small text-gray-500">Jae Chun · 1d</div>
              </div>
            </Link>
            <Link className="dropdown-item d-flex align-items-center" to="#">
              <div className="dropdown-list-image mr-3">
                <img
                  className="rounded-circle"
                  src="../../assets/admin/img/avatar.png"
                  alt="..."
                />
                <div className="status-indicator bg-warning"></div>
              </div>
              <div>
                <div className="text-truncate">
                  Last month's report looks great, I am very happy with the
                  progress so far, keep up the good work!
                </div>
                <div className="small text-gray-500">Morgan Alvarez · 2d</div>
              </div>
            </Link>
            <Link className="dropdown-item d-flex align-items-center" to="#">
              <div className="dropdown-list-image mr-3">
                <img
                  className="rounded-circle"
                  src={`../../assets/admin/img/avatar.png`}
                  alt="profile_image"
                />
                <div className="status-indicator bg-success"></div>
              </div>
              <div>
                <div className="text-truncate">
                  Am I a good boy? The reason I ask is because someone told me
                  that people say this to all dogs, even if they aren't good...
                </div>
                <div className="small text-gray-500">Chicken the Dog · 2w</div>
              </div>
            </Link>
            <Link
              className="dropdown-item text-center small text-gray-500"
              to="#"
            >
              Read More Messages
            </Link>
          </div>
        </li> */}

          <div className="topbar-divider d-none d-sm-block"></div>

          <li className="nav-item dropdown no-arrow">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                {display_image.name}
              </span>

              <img className="img-profile rounded-circle"
                src={myphoto} alt="wimage" />
            </Link>

            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <Link className="dropdown-item" to="/admin/profile">
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Profile
              </Link>
              <Link className="dropdown-item" to="/admin/setting">
                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                Settings
              </Link>
              <Link className="dropdown-item" to="#">
                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                Activity Log
              </Link>
              <div className="dropdown-divider"></div>
              <Link
                className="dropdown-item"
                to="#"
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </Link>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
