
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

function AddCoupon() {
    document.title = "Add Coupon | Mobile Voucher"; // this will show the page title name
    //let set the input veriable details here
    const [couponInput, setCoupon] = useState({
        coupon_code: '',
        error_list: [],
    });
    const [isLoading, setIsLoading] = useState(false);

    // declear input handling function here
    const handleInput = (e) => {
        e.persist();
        setCoupon({ ...couponInput, [e.target.name]: e.target.value })
    }
    // let create function to send request to save the data via a api url
    const submitCoupon = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            coupon_code: couponInput.coupon_code,
        }

        // let create the api url here
        axios.post(`/api/save_coupon`, data).then(res => {
            if (res.data.status === 200) {
                // successful message
                toast.success(res.data.message, { position: 'top-center', theme: 'colored' });
                setCoupon({
                    ...couponInput,
                    coupon_code: '',
                });

            }
            // invalid code entered
            else if (res.data.status === 402) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // data input required
            else if (res.data.status === 422) {
                toast.warning('Missing Data Required', { position: 'top-center', theme: 'colored' });
                setCoupon({ ...couponInput, error_list: res.data.errors });
            }
            // error record not save
            else if (res.data.status === 500) {
                toast.warning('Missing Data Required', { position: 'top-center', theme: 'colored' });
                setCoupon({ ...couponInput, error_list: res.data.errors });
            }
            // login required
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            else {
                toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });
            }
            setIsLoading(false);
        });

    }
    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Add Coupon</h1>
                <Link to="/admin/dashboard" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"> <i className="fa fa-home"></i> Dashboard </Link>
            </div>
            <div className="row">
                <div className="col-lg-6 mb-4">

                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary"> Redeem your code</h6>
                        </div>
                        <div className="card-body">
                            <form onSubmit={submitCoupon} className="user">

                                <div className="form-group">
                                    <input type="text" name="coupon_code" onChange={handleInput} value={couponInput.coupon_code} className="form-control form-control-user"
                                        placeholder="Enter Coupon/Gift Card Code" />
                                    <span className='text-danger'>{couponInput.error_list.coupon_code}</span>
                                    {/* <span className='text-danger'>{loginInput.error_list.email}</span> */}
                                </div>

                                <hr />
                                <button type="submit" disabled={isLoading} className="btn btn-primary">
                                    {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}

                                    Add
                                </button>

                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AddCoupon;