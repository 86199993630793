
import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import axios from "axios";

import { UserContext } from '../../context/UserContext';

function Dashboard() {
    document.title = "Dashboard | Mobile Voucher"; // this will show the page title name

    const history = useHistory();
    /* this will check if users have not logged in then send them back to home page or login page */
    axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
        if (err.response.status === 401) {
            toast.warning(err.response.data.message, {
                theme: "colored"
            });
            history.push('/');
        }
        return Promise.reject(err);
    });

    axios.interceptors.response.use(function (response) {
        return response
    }, function (error) {
        if (error.response.status === 403) // this call access denied error
        {
            toast.warning(error.response.data.message, {
                theme: "colored"
            });
            history.push('/403');
        }
        else if (error.response.status === 404) // this call page not found
        {
            toast.warning("Sorry! It seem you are checking wrong page", {
                theme: "colored"
            });
            history.push('/404');
        }
        return Promise.reject(error);
    }

    )
    // if (!localStorage.getItem("Auth_logged")) {
    //     history.push("/login");
    // }
    // if (localStorage.getItem("auth_name") === null || localStorage.getItem("auth_name") === "") {
    //     history.push("/login");
    // }

    const { user } = React.useContext(UserContext);
    //const [logged_status, setLoggedStatus] = loggin_status;
    const [user_details] = user;

    const [record_balance, setRecordBalance] = useState([]);
    const [total_withdraw, setTotalWithdraw] = useState([]);
    const [total_transfer, setTotalTransfer] = useState([]);
    const [total_receive, setTotalReceive] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

    //const value = useContext(UserContext);
    //const [users, setUsers] = useContext(UserContext);



    // create a function to fetch total balance here
    const shouldlog = useRef(false);
    const getTransactionDetails = () => {
        setIsLoading(true);
        // let create the api url here
        axios.get(`/api/user_transaction`).then(res => {
            if (res.data.status === 200) {
                setRecordBalance(res.data.record_data.total_balance);
                setTotalWithdraw(res.data.record_data.total_withdraw);
                setTotalTransfer(res.data.record_data.total_transfer);
                setTotalReceive(res.data.record_data.total_receive);

            }
            // login required
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            else {
                toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });
            }
            setIsLoading(false);
        });
    }
    useEffect(() => {
        if (!shouldlog.current) {

            // call the function here
            getTransactionDetails();
        }
        return () => {
            shouldlog.current = false;
        }
    }, []);


    // const currentTime = new Date().toLocaleString();
    // const currDate = new Date().toLocaleDateString();
    const today = new Date();
    //const curTime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    //console.log(today.toLocaleTimeString('en-US', { hour12: false }));
    const curTimenow = (today.toLocaleTimeString('en-US', { hour12: true }));

    // const current = new Date();
    // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
    let currenDateTime = new Date().toLocaleDateString('en-us', { weekday: "long", month: "long", day: "numeric" });
    //console.log(currenDateTime)

    if (isLoading) {
        return (
            <div style={style}>
                <div className="spinner-border spinner-border-sm" role="status">
                </div> Loading
            </div>
        )
    }
    else {


        //console.log(auth);

        return (
            <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Dashboard  {user_details.email}</h1>
                    <Link to="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"> Last Login {currenDateTime + ' '}
                        | <i className="fa fa-clock-o"></i> {curTimenow}

                    </Link>
                </div>
                <div className="row">


                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            All Time Balance</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                                            {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            ${(record_balance).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-warning shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                            Total Transfer</div>
                                        {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                                            ${(total_transfer).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Total Received
                                        </div>
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-auto">
                                                {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}

                                                <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                                    ${(total_receive).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-danger shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                                            Total Withdraw</div>
                                        {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">$
                                            {(total_withdraw).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br /><br /><br></br>
                <div className="row">
                    <div className="col-lg-6 mb-4">

                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Account Details</h6>
                            </div>
                            <div className="card-body">
                                <h4 className="small font-weight-bold">Inflow <span
                                    className="float-right">100%</span></h4>
                                <div className="progress mb-4">
                                    <div className="progress-bar bg-success" role="progressbar"
                                        style={{ width: "100%" }}

                                        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <h4 className="small font-weight-bold">Balance <span
                                    className="float-right">100%</span></h4>
                                <div className="progress mb-4">
                                    <div className="progress-bar bg-primary" role="progressbar"
                                        style={{ width: "100%" }}
                                        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <h4 className="small font-weight-bold">Outflow <span
                                    className="float-right">50%</span></h4>
                                <div className="progress mb-4">
                                    <div className="progress-bar bg-warning" role="progressbar"
                                        style={{ width: "50%" }}
                                        aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>

                                <h4 className="small font-weight-bold">Loan<span
                                    className="float-right">15%</span></h4>
                                <div className="progress">
                                    <div className="progress-bar bg-secondary" role="progressbar"
                                        style={{ width: "15%" }}
                                        aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 mb-4">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Card's Overview</h6>
                            </div>
                            <div className="card-body">
                                <div className="text-center">
                                    <div className="row py-6 ">
                                        <div className="svg vis"> <svg xmlns="http://www.w3.org/2000/svg" className="piechart">
                                            <path d="M66,66 L130,66  A64,64 0 0,1 57,129 z" fill="#327ea3"></path>
                                            <path d="M66,66 L57, 129 A64,64 0 0,1 2,60 z" fill="#4ca5d0"></path>
                                            <path d="M66,66 L2,  60  A64,64 0 0,1 34,11 z" fill="#6ca338"></path>
                                            <path d="M66,66 L34, 11  A64,64 0 0,1 103,14 z" fill="#ffbc38"></path>
                                            <path d="M66,66 L103,14  A64,64 0 0,1 130,66 z" fill="#e82c0c"></path>
                                            <circle cx="66" cy="66" r="40" fill="#ffffff"></circle>
                                        </svg>
                                        </div>
                                    </div>

                                </div>
                                <p>Overview of all transaction in the card <Link
                                    target="_blank" rel="nofollow" to="#">Maintain</Link>, the monthly limit of the card</p>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default Dashboard;