import React from "react";
import { Link } from "react-router-dom";
// get current year here
const today = new Date();
//ends
const Footer = () => {

    return (
        <footer className="sticky-footer bg-white">
            <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    Mobile Voucher System | <span>Copyright &copy; Your Website {today.getFullYear()}, Designed By <Link to={{ pathname: "https://zictech-ng.com" }} target="_blank"> Zictech Technologies</Link></span>
                </div>
            </div>
        </footer>

    );
};

export default Footer;