
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

function TransferFunds() {
    document.title = "Transfer Cash | Mobile Voucher"; // this will show the page title name

    //let set the input veriable details here
    const [transferInput, setTransfer] = useState({
        amount_cash: '',
        receiver_email: '',
        send_note: '',
        error_list: [],
    });

    const [isLoading, setIsLoading] = useState(false);

    // declear input handling function here
    const handleInput = (e) => {
        e.persist();
        setTransfer({ ...transferInput, [e.target.name]: e.target.value })
    }
    // let create function to send request to save the data via a api url
    const submitTransfer = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            amount_cash: transferInput.amount_cash,
            receiver_email: transferInput.receiver_email,
            send_note: transferInput.send_note
        }

        // let create the api url here
        axios.post(`/api/transfer_funds`, data).then(res => {
            if (res.data.status === 200) {
                // successful message
                toast.success(res.data.message, { position: 'top-center', theme: 'colored' });
                setTransfer({
                    ...transferInput,
                    amount_cash: '',
                    receiver_email: '',
                    send_note: '',
                });

            }
            // data input required
            else if (res.data.status === 422) {
                toast.warning('Missing Data Required', { position: 'top-center', theme: 'colored' });
                setTransfer({ ...transferInput, error_list: res.data.errors });
            }
            // invalid code entered
            else if (res.data.status === 402) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }

            // error low wallet balance
            else if (res.data.status === 403) {
                toast.warning(res.data.message, { position: 'top-center', theme: 'colored' });
            }

            // error email receiver not found
            else if (res.data.status === 404) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            // operation failed while trying to send funds! Try again
            else if (res.data.status === 501) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }

            // login required
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            else {
                toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });
            }
            setIsLoading(false);
        });

    }
    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Send Funds</h1>
                <Link to="/admin/dashboard" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"> <i className="fa fa-home"></i> Dashboard </Link>
            </div>
            <div className="row">
                <div className="col-lg-6 mb-4">

                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary"> Send coupon cash to friends</h6>
                        </div>
                        <div className="card-body">
                            <form onSubmit={submitTransfer} className="user">

                                <div className="form-group">
                                    <input type="text" name="amount_cash" onChange={handleInput} value={transferInput.amount_cash} className="form-control"
                                        placeholder="Enter Amount (ex: 10)" />
                                    <span className='text-danger'>{transferInput.error_list.amount_cash}</span>
                                    {/* <span className='text-danger'>{loginInput.error_list.email}</span> */}
                                </div>
                                <div className="form-group">
                                    <input type="text" name="receiver_email" onChange={handleInput} value={transferInput.receiver_email} className="form-control"
                                        placeholder="Receiver Email ID" />
                                    <span className='text-danger'>{transferInput.error_list.receiver_email}</span>
                                    {/* <span className='text-danger'>{loginInput.error_list.email}</span> */}
                                </div>

                                <div className="form-group">
                                    <textarea name="send_note" onChange={handleInput} value={transferInput.send_note} className="form-control" placeholder="Short note (Purpose)"></textarea>

                                    {/* <span className='text-danger'>{loginInput.error_list.email}</span> */}
                                </div>

                                <hr />
                                <button type="submit" disabled={isLoading} className="btn btn-primary">
                                    {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Send
                                </button>

                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default TransferFunds;