
import React, { useState, createContext, useEffect } from "react";
//import { toast } from "react-toastify";
import axios from "axios";
//import { useHistory } from "react-router-dom";
export const UserContext = createContext();

export const UserProvider = props => {
    // const history = useHistory();
    //const user_email = localStorage.getItem('auth_email');
    //const [users, setUsers] = useState([""]);
    const [user_details, setUserDetails] = useState({});
    const [logged_status, setLoggedStatus] = useState(false);
    const [photo_status, setPhtotStatus] = useState([]);

    //const [loading, setloading] = useState(true);

    useEffect(() => {
        const getLoggedInUser = async () => {
            try {
                const email = localStorage.getItem("auth_email");
                if (!email) return;

                const res = await axios.get(`/api/load_user/${email}`);
                setUserDetails(res.data.userDetails);
                setLoggedStatus(true);
            } catch (error) {
                // Handle the error
            }
        };

        getLoggedInUser();
    }, [])

    // call the function here
    // const getUsers = () => {
    //     const myemail = localStorage.getItem("auth_email");
    //     if (user_email) {
    //         // let create the api url here
    //         axios.get(`/api/load_user/${myemail}`).then(res => {
    //             if (res.data.status === 200) {
    //                 setUserDetails(res.data.userDetails);
    //                 setLoggedStatus(true);
    //             }
    //             // login required
    //             else if (res.data.status === 401) {
    //                 toast.error(res.data.message, { position: 'top-center', theme: 'colored' });

    //             }
    //             else {
    //                 toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });

    //             }
    //             setloading(false);
    //         }).catch(error => {
    //             console.log("User details error", error);
    //         });
    //     }
    // }

    // useEffect(() => {
    //     getUsers();
    // }, []);

    return (
        <UserContext.Provider value={{ loggin_status: [logged_status, setLoggedStatus], user: [user_details, setUserDetails], my_photo: [photo_status, setPhtotStatus] }}>
            {props.children}
        </UserContext.Provider>

    );
}


// different way to handle multiple useContext

// import React, { useState, createContext } from 'react';

// const GameContext = createContext();

// const GameProvider = ({ children }) => {
//   const [state, setState] = useState({
//        name: '',
//        color: '',
//        startgame: false
//   });


//   return (
//     <GameContext.Provider value={{
//          ...state, 
//          setState: (data) => setState({...state, ...data})
//        }}
//     >
//       {children}
//     </GameContext.Provider>
//   );
// };

// export { GameContext, GameProvider };


// // then call it like this in the component/page where you want to use it.
// import { GameContext } from '../../context/GameContext';    
// const {name, color, startGame, setState} = useContext(GameContext);
// console.log(name);
// // Updating name
// setState({ name: 'test' });