import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
//import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';

const PER_PAGE = 10;

function History() {
    let allRecordHistoryRef = useRef([]);
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    document.title = "History | Mobile Voucher"; // this will show the page title name
    const [currentPage, setCurrentPage] = useState(0);

    const [record_history, setRecordHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search_query, setSearchQuery] = useState('');

    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

    useEffect(() => {
        if (search_query.length === 0) return setRecordHistory(allRecordHistoryRef.current)
        const timeout = setTimeout(() => {
            setIsLoading(true);
            axios.get(`/api/search/${search_query}`).then(res => {
                setRecordHistory(res.data)

            }).catch(err => {
                console.log(err)
            })
            setIsLoading(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, [search_query])

    // delete history details here
    const deleteHistory = (e, id) => {
        e.preventDefault();
        const thisClicked = e.currentTarget;
        thisClicked.innerHTML = "<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span><span class='sr-only'></span>";
        /* send axios request to delete the record from the database here */
        axios.delete(`/api/delete-history/${id}`).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.message, { theme: 'colored' });
                thisClicked.closest("tr").remove();
            }
            else if (res.data.status === 404) {
                toast.warning(res.data.message, { theme: 'colored' });
                thisClicked.innerHTML = "<i className='fa fa-trash-o'></i>";
            }
        })
    }

    // useEffect(() => {
    //     if (search_query.length === 0) setRecordHistory(allRecordHistoryRef.current);

    //     const filteredResults = allRecordHistoryRef.current.filter(recordHistory => {
    //         const keys = Object.keys(recordHistory);

    //         for (const key of keys) {
    //             const value = String(recordHistory[key]);
    //             if (value.includes(search_query)) return true;
    //         }
    //         return false;
    //     })

    //     setRecordHistory(filteredResults)

    // }, [search_query])

    // create a function to fetch history here
    const getHistory = (e) => {
        setIsLoading(true);
        // let create the api url here
        axios.get(`/api/fetch_history`).then(res => {
            if (res.data.status === 200) {
                allRecordHistoryRef.current = res.data.history_record;
                setRecordHistory(res.data.history_record);
                //console.log(res.data.history_record);

            }
            // login required
            else if (res.data.status === 401) {
                toast.error(res.data.message, { position: 'top-center', theme: 'colored' });
            }
            else {
                toast.error("sorry, something went wrong! Try again.", { position: 'top-center', theme: 'colored' });
            }
            setIsLoading(false);
        });
    }
    useEffect(() => {
        // call the function here
        getHistory();
        //search();
        return () => {

        };
    }, []);

    // pagination click handling here
    function handlePageClick({ selected: selectedPage }) {
        //console.log("selectedPage", selectedPage);
        setCurrentPage(selectedPage);
    }
    // 0, 10, 20, 30...
    const offset = currentPage * PER_PAGE;
    // console.log("offset", offset)

    //total pages
    let pageCount = Math.ceil(record_history.length / PER_PAGE);
    /* set laoding icon to display while page is fetching data from api */
    // if (isLoading) {
    //     return (
    //         <div style={style}>
    //             <div className="spinner-border spinner-border-sm" role="status">
    //             </div> Loading
    //         </div>
    //     )
    // }
    /* create veriable to hold the result data */
    var display_historydata = "";
    var table_HTML = "";

    if (record_history.length > 0) {
        var newStatus = '';
        /* auto increase row number serial: viewProduct.map((item, i) <tr key{i} <td>{i+1}</td> */
        display_historydata = record_history
            .slice(offset, offset + PER_PAGE)
            .map((item, i) => {
                const udate = new Date((item.created_at));
                //console.log(date.toLocaleDateString('en-GB', DATE_OPTIONS));
                const create_date = (udate.toLocaleDateString('en-GB', DATE_OPTIONS));
                if (item.status === 'Credit')// this mean product is active
                {
                    newStatus = <span className='badge bg-success'>Credit</span>
                }
                else if (item.status === 'Debit') {
                    newStatus = <span className='badge bg-danger'>Debit</span>;
                }
                return (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.send_amt}</td>
                        <td>{newStatus}</td>
                        <td>{item.action_nature}</td>
                        <td>{create_date}</td>
                        <td><button type='button' onClick={(e) => deleteHistory(e, item.id)} className='btn btn-danger btn-sm' data-bs-toggle="tooltip" data-bs-placement="top" title="Trash History Record"><i className='fa fa-trash-o'></i></button></td>

                    </tr>

                )
            })


        table_HTML = <div className="card shadow mb-4">
            {isLoading && <div style={style}>
                <div className="spinner-border spinner-border-sm" role="status">
                </div> Loading
            </div>}

            <h5 className="card-header">
                <div className='row'>
                    <div className='col-md-6'>
                        Transaction Details
                    </div>
                    <div className='col-md-5'>

                        <div className="input-group small">
                            <input type="text" name="key" onChange={(e) => setSearchQuery(e.target.value)} className="form-control small"
                                placeholder="Search details" />
                            <div className="input-group-append">
                                {/* <button type="button" className="btn btn-primary" >
                                    <i className="fa fa-search"></i>
                                </button> */}
                            </div>
                        </div>

                        {/* <div className='form-group d-flex float-end'>
                            <input type='text' onChange={(e) => setSearchQuery(e.target.value)} className='form-control small' placeholder='Search...' />
                            <button onClick={() => handleSearch()} className='btn btn-primary small mr-2 text-right'><i className='fa fa-search'></i></button>
                        </div> */}

                    </div>

                </div>

            </h5>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Description</th>
                                <th>Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            {display_historydata}
                        </tbody>
                    </table>

                    <nav aria-label="Page navigation example">

                        <ul className="pagination pagination-sm justify-content-end align-items-center">
                            <span className='mr-2'>{currentPage + 1} of {pageCount} </span>
                            <ReactPaginate
                                breakLabel="..."
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                                disabledClassName={"page-item disabled"}
                                activeClassName={"active"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                pageRangeDisplayed={PER_PAGE}
                                pageCount={pageCount}
                                renderOnZeroPageCount={null}
                            />
                        </ul>
                    </nav>

                </div>
            </div>
        </div>
    }
    /* end loading display here */
    else {
        table_HTML = <div className="card shadow mb-4">
            <div className="card-body">
                <div className="table-responsive">
                    <h4>No transaction history at the moment</h4>
                </div>
            </div>
        </div>
    }
    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Transaction History</h1>
                <Link to="/admin/dashboard" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"> <i className="fa fa-home"></i> Dashboard </Link>
            </div>

            {table_HTML}

            {/* <div className="card shadow mb-4">
                <h5 className="card-header">

                    <div className='row'>
                        <div className='col-md-5'>
                            Transaction Details
                        </div>
                        <div className='col-sm-4 d-flex justify-content-between '>
                            <input type='text' onChange={(e) => setSearchQuery(e.target.value)} className='form-control float-end small' placeholder='Search...' />
                            <button onClick={() => handleSearch()} className='btn btn-primary small mr-2'><i className='fa fa-search'></i></button>
                        </div>

                    </div>


                </h5>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Description</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {record_history &&
                                    record_history.filter(item => {
                                        if (search_query === '') {
                                            return item;
                                        }
                                        else if (item.send_amt.toLowerCase().includes(search_query.toLocaleLowerCase())) {
                                            return item;
                                        }
                                    }).map((item, i) => {

                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{item.send_amt}</td>
                                                <td>{item.status}</td>
                                                <td>{item.action_nature}</td>
                                                <td>{item.created_at}</td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                        <nav aria-label="">
                            <ul className="pagination">
                                <li className="page-item"><Link className="page-link" to="#">Previous</Link></li>
                                <li className="page-item active"><Link className="page-link" to="#">1</Link></li>
                                <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                <li className="page-item"><Link className="page-link" to="#">Next</Link></li>
                            </ul>

                        </nav>
                    </div>

                </div>
            </div> */}
        </>
    )
}

export default History;